<template>
  <div class="page-tag-view">
    <div class="header" v-if="mainTag !== undefined">
      <div class="title">
        <h4 class="title-text text">You are viewing posts tagged with:</h4>
        <tag-pill
          class="tag-pill"
          :name="mainTag.name"
          :color="mainTag.color"
          :fontSize="1.1"
          :key="mainTag.name"></tag-pill>
      </div>
      <div class="spacer"></div>
      <div class="color-vote-container" v-if="votingEnabled">
        <h5 class="primary-text text">Vote for the best color for this tag!</h5>
        <button class="button button-success disabled">vote</button>
      </div>
    </div>
    <posts-container
      ref="container"
      :posts="posts"
      name="home"
      @changeFilters="onChangeFilter"
      @reachedEnd="onReachedEnd"></posts-container>
  </div>
</template>

<script>
import axios from "axios";
import PostsContainer from "../components/posts/PostsLargeContainer.vue";
import TagPill from "../components/basic/TagPill.vue";
export default {
  name: "TagView",
  components: {
    PostsContainer,
    TagPill,
  },
  data() {
    return {
      lastPostRecieved: 0,
      sorting: "popular",
      time: "recent",
      filter: "none",
      posts: [],
      mainTag: undefined,
    };
  },
  computed: {
    votingEnabled() {
      return false; //TODO fix this
    },
  },
  methods: {
    getNextFewPosts(tag = this.$route.params.tag) {
      this.$store.commit("setLoading", true);
      axios
        .get("/api/v1/posts/tag/" + tag + "/", {
          params: {
            from: this.lastPostRecieved,
            time: this.time,
            filter: this.filter,
            sorting: this.sorting,
          },
        })
        .then((res) => {
          console.log(res);
          this.$store.commit("setLoading", false);
          document.title = "tag: " + tag + " | icandoathing";

          if (res.data.posts.length > 0) {
            this.mainTag = res.data.posts[0].tags.filter(
              (x) => x.name == tag
            )[0];
            console.log(res.data.posts[0].tags.filter((x) => x.name == tag)[0]);
          }
          if (res.data.is_end) {
            this.$refs.container.setLoadedActive(false);
          } else {
            this.$refs.container.setLoadedActive(true);
          }
          res.data.posts.forEach((post) => {
            this.posts.push(post);
            this.lastPostRecieved++;
          });
        });
    },
    onChangeFilter(data) {
      this.sorting = data.sorting || "popular";
      this.time = data.time || "recent";
      this.filter = data.filter || "none";
      this.lastPostRecieved = 0;
      this.posts.splice(0);
      this.getNextFewPosts();
    },
    onReachedEnd() {
      console.log("reached the end, gonna load");
      this.$refs.container.setLoading(true);
      this.getNextFewPosts().then(() => {
        this.$refs.container.setLoading(false);
      });
    },
  },
  mounted() {
    this.$store.dispatch("ping");
    this.getNextFewPosts();
  },
  beforeRouteUpdate(to, from, next) {
    this.posts.splice(0);
    this.lastPostRecieved = 0;
    requestAnimationFrame(() => {
      this.getNextFewPosts(to.params.tag);
      next();
    });
  },
};
</script>

<style lang="scss" scoped>
.page-tag-view {
  width: 100%;
}
.header {
  display: flex;
  background-color: $grey-300;
  margin: $item-margin;
  padding: $item-margin;
  border-radius: $border-radius;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-text {
  font-size: 1.3rem;
  font-weight: 300;
  margin-left: 1rem;
}
.tag-pill {
  margin: 0.1rem 1rem;
}
.color-vote-container {
  display: flex;
  align-items: center;
}
.button {
  margin: 0 1rem;
}
@media screen and (max-width: $switch-width) {
  .header {
    padding: 0.1rem;
  }
  .title-text {
    font-size: 1rem;
    margin: 0.3rem;
  }
  .spacer {
    display: none;
  }
}
</style>
